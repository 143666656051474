import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/database';

let config = {
    apiKey: "AIzaSyDeHQhzt39yo14eNt2SRZhmG75N2zPaVyk",
    authDomain: "queue-management-45eb2.firebaseapp.com",
    databaseURL: "https://queue-management-45eb2-default-rtdb.firebaseio.com",
    projectId: "queue-management-45eb2",
    storageBucket: "queue-management-45eb2.appspot.com",
    messagingSenderId: "432985435017",
    appId: "1:432985435017:web:18eb5b2d9ed82e0efc0f58",
    measurementId: "G-FE9GMNKB3T",
};

firebase.initializeApp(config);

const database = firebase.database();

export default database;