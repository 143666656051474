import React, { Component } from 'react';
//import { TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import database from "../utils/firebase";
import { Tab, Tabs, ListGroup, InputGroup, FormControl, Button } from 'react-bootstrap';
import "./Admin-css.css";
import "./style.css"; // import the CSS file
import "./listStyle.css";
import { ScatterChart, Scatter, ZAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, Cell, LineChart, XAxis, YAxis, Line } from 'recharts';

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      branches: {},
      services: {},
      newBranch: "",
      branchCode: "",
      newService: "",
      newServiceCount: 1,
      tickets: [],
      totalTicketCount: 0,
      serviceCount: {},
      searchQuery: "",
      fromDate: "",
      toDate: "",
      tempFromDate: "",
      tempToDate: "",
      companyCount: {},
      bubbleData: [],
      companyQuery: "",
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  searchBranches = (e) => {
    this.setState({ searchQuery: e.target.value });
  };

  searchByCompanyName = (e) => {
    this.setState({ companyNameQuery: e.target.value });
  };

  applyCompanyNameFilter = () => {
    this.setState({
      companyNameFilter: this.state.companyNameQuery
    }, this.fetchData); // then fetch data
  }


  fetchData = () => {
    const branchesRef = database.ref('/Branches');
    const servicesRef = database.ref('/Services');
    const ticketsRef = database.ref('/tickets');

    branchesRef.on('value', (snapshot) => {
      this.setState({ branches: snapshot.val() });
    });

    servicesRef.on('value', (snapshot) => {
      this.setState({ services: snapshot.val() || {} });
    });

    ticketsRef.on('value', (snapshot) => {
      let tickets = snapshot.val();
      if (tickets) {
        if (!Array.isArray(tickets)) {
          tickets = Object.values(tickets);
        }

        let filteredTickets = tickets;
        if (this.state.fromDate && this.state.toDate) {
          const fromDate = new Date(this.state.fromDate);
          const toDate = new Date(this.state.toDate);
          toDate.setHours(23, 59, 59, 999);  // Set the time to the end of the day
          filteredTickets = tickets.filter(ticket => {
            const [day, month, year, time] = ticket.assignedDate.split(/\//).join(' ').split(/ /);
            const [hour, minute, second] = time.split(':');
            const assignedDate = new Date(year, month - 1, day, hour, minute, second);
            return assignedDate >= fromDate && assignedDate <= toDate;
          });
        }

        if (this.state.companyQuery) {
          filteredTickets = filteredTickets.filter(ticket => ticket.companyName.toLowerCase().includes(this.state.companyQuery.toLowerCase()));
        }

        // Calculate total ticket count
        let totalTicketCount = 0;
        let serviceCount = {};

        for (const ticket of filteredTickets) {
          totalTicketCount++;
          if (serviceCount[ticket.service]) {
            serviceCount[ticket.service]++;
          } else {
            serviceCount[ticket.service] = 1;
          }
        }

        this.setState({ tickets: filteredTickets, totalTicketCount, serviceCount }, () => {
          this.processTickets();
        });
      }
    });
  };

  processTickets() {
    const companyCount = {};
    for (const ticket of this.state.tickets) {
      const companyName = ticket.companyName;
      if (!companyCount[companyName]) {
        companyCount[companyName] = 0;
      }
      companyCount[companyName]++;
    }

    const bubbleData = Object.entries(companyCount).map(([company, count]) => ({ company, count }));

    this.setState({ companyCount, bubbleData });
  }

  handleDateFilterChange = () => {
    // update the actual filter dates in the state with the new filter dates
    this.setState({
      fromDate: this.state.tempFromDate,
      toDate: this.state.tempToDate
    }, this.fetchData); // then fetch data
  }

  addService = () => {
    const { newService, newServiceCount } = this.state;

    if (newService) {
      const servicesRef = database.ref('/Services');
      servicesRef.update({ [newService]: parseInt(newServiceCount) });

      this.setState({ newService: "", newServiceCount: 1 });
    }
  };

  deleteService = (serviceName) => {
    const servicesRef = database.ref('/Services');
    servicesRef.child(serviceName).remove();
  };

  addBranch = () => {
    const { newBranch, branchCode } = this.state;

    if (newBranch && branchCode) {
      const branchesRef = database.ref('/Branches');
      branchesRef.update({ [newBranch]: branchCode });

      this.setState({ newBranch: "", branchCode: "" });
    }
  };

  deleteBranch = (branchName) => {
    const branchesRef = database.ref('/Branches');
    branchesRef.child(branchName).remove();
  };

  toggleDeleteButton(branchName) {
    this.setState({ [branchName]: !this.state[branchName] });
  }

  generateLineChartData = () => {
    const { tickets } = this.state;
    const lineChartData = {};

    for (const ticketId in tickets) {
      const ticket = tickets[ticketId];
      const date = ticket.assignedDate.split(" ")[0];

      if (lineChartData[date]) {
        lineChartData[date]++;
      } else {
        lineChartData[date] = 1;
      }
    }

    return Object.entries(lineChartData).map(([date, count]) => ({ date, count }));
  };

  render() {
    const { branches, services, totalTicketCount, serviceCount, newBranch, branchCode, newService, newServiceCount } = this.state;
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FFCCCC', '#FFBBDD', '#A239CA',
      '#4717F6', '#E7DF98', '#4B4E6D', '#528A84', '#B2B09B', '#840032', '#D6897D', '#72A3D3',
      '#3E598A', '#B88512', '#E2C93D', '#667176', '#0A2105'];
    const lineChartData = this.generateLineChartData();

    const { companyCount } = this.state;
    return (
      <div className="Admin">
        <Tabs defaultActiveKey="services" id="admin-tabs">
          <Tab eventKey="services" title="Manage Services">
            <div className="container">
              <div className="row">
                {/* Add Service Section - Left Side */}
                <div className="col-md-6">
                  <h3>Add New Service</h3>
                  <form>
                    <div className="form-group">
                      <label htmlFor="newServiceInput">New service name:</label>
                      <input
                        id="newServiceInput"
                        type="text"
                        className="form-control"
                        placeholder="New service name"
                        value={newService}
                        onChange={(e) => this.setState({ newService: e.target.value })}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="newServiceCountInput">Wait time (Minutes):</label>
                      <input
                        id="newServiceCountInput"
                        type="number"
                        className="form-control"
                        min="1"
                        value={newServiceCount}
                        onChange={(e) => this.setState({ newServiceCount: e.target.value })}
                      />
                    </div>
                    <button className="btn btn-primary" onClick={this.addService}>Add Service</button>
                  </form>
                </div>

                {/* Services List - Right Side */}
                <div className="col-md-6">
                  <h3>Services</h3>
                  <ListGroup>
                    {Object.entries(services).map(([serviceName, count]) => (
                      <ListGroup.Item
                        key={serviceName}
                        onClick={() => this.toggleDeleteButton(serviceName)}
                      >
                        {serviceName}: {count}
                        {this.state[serviceName] && (
                          <Button
                            variant="danger"
                            onClick={(e) => {
                              this.deleteService(serviceName);
                              e.stopPropagation();
                            }}
                          >
                            Delete
                          </Button>
                        )}
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </div>
              </div>
            </div>
          </Tab>

          <Tab eventKey="branches" title="Manage Branches">
            {/* Add your code to manage branches here */}
            <div className="container">
              <div className="row">
                {/* Add Branch Section - Left Side */}
                <div className="col-md-6">
                  <h3>Add New Branch</h3>
                  <form>
                    <div className="form-group">
                      <label htmlFor="newBranchInput">New branch name:</label>
                      <input
                        id="newBranchInput"
                        type="text"
                        className="form-control"
                        placeholder="New branch name"
                        value={newBranch}
                        onChange={(e) => this.setState({ newBranch: e.target.value })}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="branchCodeInput">Branch Code:</label>
                      <input
                        id="branchCodeInput"
                        type="text"
                        className="form-control"
                        placeholder="Branch Code"
                        value={branchCode}
                        onChange={(e) => this.setState({ branchCode: e.target.value })}
                      />
                    </div>
                    <button className="btn btn-primary" onClick={this.addBranch}>Add Branch</button>
                  </form>
                </div>

                {/* Branches List - Right Side */}
                <div className="col-md-6">
                  <h3>Branches</h3>

                  <div className="branch-list" style={{ maxHeight: '300px', overflowY: 'scroll' }}>
                    <ListGroup>
                      {Object.entries(branches)
                        .sort(([branchNameA], [branchNameB]) => branchNameA.localeCompare(branchNameB))
                        .filter(([branchName]) => branchName.toLowerCase().includes(this.state.searchQuery.toLowerCase()))
                        .map(([branchName, branchCode]) => (
                          <ListGroup.Item
                            key={branchName}
                            onClick={() => this.toggleDeleteButton(branchName)}
                          >
                            {branchName}: {branchCode.toString()}
                            {this.state[branchName] && (
                              <Button
                                variant="danger"
                                onClick={(e) => {
                                  this.deleteBranch(branchName);
                                  e.stopPropagation();
                                }}
                              >
                                Delete
                              </Button>
                            )}
                          </ListGroup.Item>
                        ))}
                    </ListGroup>
                  </div>
                </div>
              </div>
            </div> {/* This closing tag was missing */}
          </Tab>

          <Tab eventKey="reports" title="Reports">
            <div className="date-filter">
              <input
                type="date"
                value={this.state.tempFromDate}
                onChange={(e) => this.setState({ tempFromDate: e.target.value })}
              />
              <input
                type="date"
                value={this.state.tempToDate}
                onChange={(e) => this.setState({ tempToDate: e.target.value })}
              />
              <button onClick={this.handleDateFilterChange}>Apply Filter</button>
            </div>

            <div className="company-filter">
              <input
                type="text"
                placeholder="Filter by company name"
                value={this.state.companyQuery}
                onChange={this.searchCompanies}
              />
            </div>


            <p>Total Ticket Count: {totalTicketCount}</p>
            {/* <div>
              {Object.entries(serviceCount).map(([serviceName, count]) => (
                <div key={serviceName}>
                  {serviceName}: {count}
                  </div>
              ))}
            </div> */}
            <div className="col-md-6">
              <LineChart width={1000} height={200} data={lineChartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="count" stroke="#8884d8" activeDot={{ r: 8 }} />
              </LineChart>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <div style={{ flex: 1, padding: '10px' }}>
                <PieChart width={400} height={400} onMouseEnter={this.onPieEnter}>
                  <Pie
                    dataKey="value"
                    isAnimationActive={false}
                    data={Object.entries(serviceCount).map(([key, value]) => ({ name: key, value }))}
                    cx={200}
                    cy={200}
                    outerRadius={135}
                    fill="#8884d8"
                    label
                  >
                    {Object.entries(serviceCount).map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend
                    layout="horizontal"
                    verticalAlign="top"
                    align="center"
                    wrapperStyle={{ fontSize: '12px' }}
                  />
                </PieChart>
              </div>
              <div style={{ flex: 1, padding: '10px' }}>
                <ScatterChart width={400} height={400} margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
                  <CartesianGrid />
                  <XAxis type="category" dataKey="company" name="company" tick={{ fontSize: '10px' }} />
                  <YAxis type="number" dataKey="count" name="count" />
                  <ZAxis type="number" dataKey="count" range={[60, 400]} />
                  <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                  <Legend />
                  <Scatter data={this.state.bubbleData} fill="#00C49F" />
                </ScatterChart>
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    );
  }
}
export default Admin;
