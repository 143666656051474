import firebase from "../utils/firebase";

const db = firebase.ref("/tickets");

class TicketDataService {
  getAll() {
    return db;
  }

  create(ticket) {
    return db.push(ticket);
  }

  update(key, value) {
    return db.child(key).update(value);
  }

  delete(key) {
    return db.child(key).remove();
  }

  deleteAll() {
    return db.remove();
  }
}

export default new TicketDataService();
