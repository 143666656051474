import React, { Component } from "react";
import TicketDataService from "../services/ticket.service";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import database from "../utils/firebase";
import { branchNameFromStorage } from "./ticket-list.component";

export default class AddTicket extends Component {
  constructor(props) {
    super(props);

    this.onChangeTicketNumber = this.onChangeTicketNumber.bind(this);
    this.onChangeService = this.onChangeService.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.getCompanyName = this.getCompanyName.bind(this);
    this.saveTicket = this.saveTicket.bind(this);
    this.newTicket = this.newTicket.bind(this);

    this.state = {
      ticketnumber: "",
      services: [],
      phone: "",
      name: "",
      companyname: branchNameFromStorage,
      completed: false,
      submitted: false,
      isLoading: false, // Add isLoading property to state
      error: null, // Add error property to state
      queuePosition: 0, // Add queuePosition property to the state
      estimatedWaitTime: 0, // Add estimatedWaitTime property to the state
    };
  }

  generateTicketNumber() {
    const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let ticketNumber = "";
    const randomBytes = new Uint8Array(6);
    window.crypto.getRandomValues(randomBytes);

    for (let i = 0; i < 6; i++) {
      const index = randomBytes[i] % chars.length;
      ticketNumber += chars[index];
    }

    return ticketNumber;
  }

  componentDidMount() {
    const servicesRef = database.ref("Services");
    servicesRef.on("value", (snapshot) => {
      let services = snapshot.val();
      let newState = [];
      for (let service in services) {
        newState.push({
          id: service,
          name: services[service],
        });
      }
      this.setState({
        services: newState,
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    // Check if branchNameFromStorage has changed
    if (branchNameFromStorage !== prevState.branchName) {
      // Update the component state with the new branch name
      this.setState({ branchName: branchNameFromStorage });
    }
  }
  

  onChangeTicketNumber(e) {
    this.setState({
      ticketnumber: e.target.value,
    });
  }

  onChangeService(e) {
    this.setState({
      service: e.target.value,
    });
  }

  onChangePhone(e) {
    this.setState({
      phone: e.target.value,
    });
  }

  onChangeName(e) {
    this.setState({
      name: e.target.value,
    });
  }

  getCompanyName() {
    return this.state.companyname;
  }

  async saveTicket() {
    const assignedDate = new Date(); // get the current date and time
    const storedCompanyName = this.getCompanyName();
    const serviceType = this.state.service;

    const data = {
      ticketNumber: this.generateTicketNumber(),
      service: serviceType,
      phone: this.state.phone,
      name: this.state.name,
      companyName: storedCompanyName,
      completed: false,
      createdBy: "Agent",
      announced: "",
      assignedDate: assignedDate.toLocaleString(), // save assigned date as human-readable string
    };

    if (!navigator.onLine) { // check if there is internet connectivity
      this.setState({
        error: "No internet connection found. Please try again later.", // Set error state to inform user of no internet connection
      });
      return;
    }

    this.setState({
      isLoading: true, // Set isLoading state to true before attempting to create the ticket
    });

    TicketDataService.create(data)
      .then(() => {
        console.log("Created new ticket successfully!");

        // Calculate queue position and estimated wait time
        const waitForService = new Promise((resolve, reject) => {
          database.ref("tickets")
            .orderByChild("companyName").equalTo(storedCompanyName)
            .on("value", (snapshot) => {
              const ticketData = snapshot.val();

              // Filter incomplete tickets for the submitted service type
              const filteredTickets = Object.values(ticketData).filter(
                (ticket) => ticket.companyName === storedCompanyName && !ticket.completed && ticket.service === serviceType
              )

              const position = filteredTickets.length + 1; // Calculate queue position

              database.ref("Services").child(serviceType).once("value", (snapshot) => {
                const serviceTime = snapshot.val();
                const waitTime = position * parseInt(serviceTime); // Calculate estimated wait time

                resolve({ position, waitTime });
              });
            });
        });

        waitForService.then(({ position, waitTime }) => {
          // Update the state with the calculated queue position and estimated wait time
          this.setState({
            queuePosition: position,
            estimatedWaitTime: waitTime,
            submitted: true,
            error: null, // Reset error state on successful submission
            isLoading: false, // Set isLoading back to false on successful submission
          });

          // Display queue position and estimated wait time
          alert(`Ticket Number: ${data.ticketNumber}
          Your queue position is: ${position} 
          Estimated wait time: ${waitTime} minutes 
          Service: ${data.service}
          Name: ${data.name}
          Phone: ${data.phone}
          Company name: ${data.companyName}
          Date: ${data.assignedDate}`);
        }).catch((error) => {
          console.log('Error calculating queue position and estimated wait time:', error);
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          error: error.message, // Update error state on unsuccessful submission
          isLoading: false, // Set isLoading back to false on unsuccessful submission
        });
      });
  }


  newTicket() {
    this.setState({
      ticketnumber: "",
      service: "",
      phone: "",
      name: "",
      companyName: "",
      completed: false,
      submitted: false,
    });
  }

  render() {
    return (
      <div className="submit-form">
        {this.state.isLoading ? (
          <div className="progress-indicator">
            Creating ticket...
          </div>
        ) : (
          this.state.submitted ? (
            <div>
              <h4>You submitted successfully!</h4>
              <button className="btn btn-success" onClick={this.newTicket}>
                Add
              </button>
            </div>
          ) : (
            <div>
              {this.state.error && (
                <div className="alert alert-danger">{this.state.error}</div>
              )}
              <div className="form-group">
                <label htmlFor="service">Service</label>
                <select
                  className="form-control"
                  id="service"
                  required
                  value={this.state.service}
                  onChange={this.onChangeService}
                  name="service"
                >
                  <option value="">Choose a service</option>
                  {this.state.services.map((service) => (
                    <option key={service.id} value={service.id}>
                      {service.id}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="phone">Phone</label>
                <input
                  type="text"
                  className="form-control"
                  id="phone"
                  //required
                  value={this.state.phone}
                  onChange={this.onChangePhone}
                  name="phone"
                />
              </div>

              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  //required
                  value={this.state.name}
                  onChange={this.onChangeName}
                  name="name"
                />
              </div>

              <button onClick={this.saveTicket} className="btn btn-success">
                Submit
              </button>
            </div>
          )
        )}
      </div>
    );
  }
}
