import React, { Component, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link, } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import AddTicket from "./components/add-ticket.component";
import TicketsList from "./components/ticket-list.component";
import Admin from "./components/admin";
import TicketHistory from "./components/TicketHistory";
import logo from './assets/sg_bank_logo.png'; // Adjust the path according to your project structure

const ADMIN_PASSWORD = "Agent"; // Define your admin password here

const AdminProtectedRoute = () => {
  const [password, setPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (password === ADMIN_PASSWORD) {
      setIsAuthenticated(true);
    } else {
      alert("Incorrect password. Please try again.");
    }
  };

  if (!isAuthenticated) {
    return (
      <div className="password-dialog"> {/* Apply the centered styling class */}
        <form onSubmit={handlePasswordSubmit}>
          <input
            type="password"
            placeholder="Enter admin password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="submit">Submit</button>
        </form>
      </div>
    );
  }

  return <Admin />;
};

class App extends Component {
  render() {
    return (
      <div>
        <nav className="navbar navbar-expand navbar-dark bg-dark">
          <a href="/tickets" className="navbar-brand">
            <img src={logo} alt="Logo" style={{ width: '70px' }} /> {/* Adjust the width as needed */}
          </a>

          <div className="navbar-nav mr-auto">
            <li className="nav-item">
              <Link to={"/tickets"} className="nav-link">
                Active Tickets
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/history" className="nav-link">
                Completed Tickets
              </Link>
            </li>
            {/* Commented out the "Add" navigation menu item */}
            {/* <li className="nav-item">
              <Link to={"/add"} className="nav-link">
                Add
              </Link>
            </li> */}
            <li className="nav-item">
              <Link to={"/admin"} className="nav-link">
                Admin
              </Link>
            </li>
          </div>
        </nav>

        <div className="container mt-3">
          {/* Add Queue Management System Inbetween the gap below */}
          <h2></h2>
          <Routes>
            <Route exact path="/tickets" element={<TicketsList />} />
            <Route exact path="/add" element={<AddTicket />} />
            <Route exact path="/admin" element={<AdminProtectedRoute />} /> {/* Use AdminProtectedRoute */}
            <Route exact path="/history" element={<TicketHistory />} />
          </Routes>
        </div>
      </div>
    );
  }
}

export default App;
