import React, { Component } from "react";
import TicketDataService from "../services/ticket.service";
import { getDeskName } from "./ticket-list.component";
import "./style.css"; // import the CSS file

export default class Ticket extends Component {
  constructor(props) {
    super(props);
    this.onChangeTicketNumber = this.onChangeTicketNumber.bind(this);
    this.onChangeService = this.onChangeService.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeCompanyName = this.onChangeCompanyName.bind(this);
    this.updateCompleted = this.updateCompleted.bind(this);
    this.updateTicket = this.updateTicket.bind(this);
    this.deleteTicket = this.deleteTicket.bind(this);
    this.beckon = this.beckon.bind(this);
    this.releaseSpeaker = this.releaseSpeaker.bind(this);
    this.updateAnnouncementToAnnounced = this.updateAnnouncementToAnnounced.bind(this);
    this.updateCompletedDateToEmpty = this.updateCompletedDateToEmpty.bind(this);

    this.state = {
      currentTicket: {
        key: null,
        ticketnumber: "",
        service: "",
        phone: "",
        name: "",
        companyname: "",
        completed: false,
        reason: "",
      },
      message: "",
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { ticket } = nextProps;
    if (ticket.key !== prevState.currentTicket.key) {
      return {
        currentTicket: ticket,
        message: "",
      };
    }

    return null;
  }


  componentDidMount() {
    this.setState({
      currentTicket: this.props.ticket,
    });
  }

  onChangeTicketNumber(e) {
    const ticketnumber = e.target.value;

    this.setState(function (prevState) {
      return {
        currentTicket: {
          ...prevState.currentTicket,
          ticketnumber: ticketnumber,
        },
      };
    });
  }

  onChangeService(e) {
    const service = e.target.value;

    this.setState((prevState) => ({
      currentTicket: {
        ...prevState.currentTicket,
        service: service,
      },
    }));
  }

  onChangePhone(e) {
    const phone = e.target.value;

    this.setState((prevState) => ({
      currentTicket: {
        ...prevState.currentTicket,
        phone: phone,
      },
    }));
  }

  onChangeName(e) {
    const name = e.target.value;

    this.setState((prevState) => ({
      currentTicket: {
        ...prevState.currentTicket,
        name: name,
      },
    }));
  }

  onChangeCompanyName(e) {
    const companyname = e.target.value;

    this.setState((prevState) => ({
      currentTicket: {
        ...prevState.currentTicket,
        companyname: companyname,
      },
    }));
  }

  // Method to handle reason selection
  handleReasonChange = (e) => {
    this.setState({ reason: e.target.value });
  };

  // Method to update completed status with reason
  updateCompleted = () => {
    const { reason } = this.state;
    if (!reason) {
      alert("Please select a reason for completing the ticket.");
      return;
    }

    const confirmed = window.confirm("Are you sure you want to change the ticket status to Completed?");
    if (!confirmed) {
      return; // Exits if user cancels the confirmation
    }

    const now = new Date();
    const updateTicket = {
      completed: true,
      completedDate: now.toLocaleString(),
      completedReason: reason,
    };

    this.updateAnnouncementToAnnounced();

    TicketDataService.update(this.state.currentTicket.key, updateTicket)
      .then(() => {
        this.setState((prevState) => ({
          currentTicket: {
            ...prevState.currentTicket,
            ...updateTicket,
          },
          message: "Ticket updated successfully!",
        }));
      })
      .catch((e) => {
        console.log(e);
        this.setState({ message: "An error occurred while updating the ticket." });
      });
  }


  // Function to confirm an action with a dialog
  confirmAction = (message) => {
    return window.confirm(message);
  }


  updateCompletedDateToEmpty() {
    TicketDataService.update(this.state.currentTicket.key, {
      completedDate: "",
    })
  }

  updateAnnouncementToAnnounced() {
    TicketDataService.update(this.state.currentTicket.key, {
      announced: "Announced",
    })
  }

  updateTicket() {
    if (window.confirm("Are you sure you want to update this ticket?")) {
      const data = {
        ticketNumber: this.state.currentTicket.ticketnumber,
        service: this.state.currentTicket.service,
        phone: this.state.currentTicket.phone,
        name: this.state.currentTicket.name,
        companyName: this.state.currentTicket.companyname,
      };

      TicketDataService.update(this.state.currentTicket.key, data)
        .then(() => {
          this.setState({
            message: "The ticket was updated successfully!",
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  beckon() {
    // Check if deskName is not empty
    const currentDeskName = getDeskName();
    if (currentDeskName && currentDeskName !== "") {
      if (navigator.onLine) {
        const data = {
          announced: "", // Clear the value first
        };

        TicketDataService.update(this.state.currentTicket.key, data)
          .then(() => {
            const newdata = {
              announced: `Attention! the Customer with Ticket ${this.state.currentTicket.ticketnumber} . Ticket ${this.state.currentTicket.ticketnumber} . ${this.state.currentTicket.ticketnumber} . Please approach ${currentDeskName}`, //TODO: Variable appropriation
            };
            return TicketDataService.update(this.state.currentTicket.key, newdata);
          })
          .then(() => {
            this.setState({
              message: "Customer beckoned",
            });
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        console.log("No internet connection");
        alert("No internet connection");
      }
    } else {
      console.log("currentDeskName is empty or not updated");
      alert("currentDeskName is empty or not updated");
    }
  }

  releaseSpeaker() {
    const data = {
      announced: "", // Clear the value first
    };
    TicketDataService.update(this.state.currentTicket.key, data)
      .then(() => {
        this.setState({
          message: "Speaker released for this ticket.",
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  deleteTicket() {
    if (window.confirm("Are you sure you want to delete this ticket?")) {
      TicketDataService.delete(this.state.currentTicket.key)
        .then(() => {
          this.setState({
            currentTicket: {
              ...this.state.currentTicket,
              key: null,
            },
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  render() {
    const { currentTicket, reason } = this.state;
    const predefinedOptions = ["Customer was attended to", "Customer did not respond", "Could not attend to customer, Business Closed for the day", "Ticket generated in error"];
    return (
      <div>
        <h4>Ticket Information</h4>

        <button
          className="badge badge-danger mr-2 complete"
          onClick={this.beckon}
          style={{ backgroundColor: "green", color: "white" }}
        >
          Beckon
        </button>

        {/* Add the "Release Speaker" button */}
        <button
          className="badge badge-danger mr-2 complete"
          onClick={this.releaseSpeaker}
          style={{ backgroundColor: "grey", color: "white" }}
        >
          Release Speaker
        </button>

        {/* Delete button commented out */}
        {/* <button
          className="badge badge-danger mr-2 incompleted"
          onClick={this.deleteTicket}
          style={{ backgroundColor: "black", color: "red" }}
        >
          Delete
        </button> */}

        <button
          type="submit"
          className="badge badge-success complete"
          onClick={this.updateTicket}
          style={{ backgroundColor: "black", color: "white" }}
        >
          Update
        </button>

        <div className="form-group">
          <label>Was this Ticket attended to?:</label>
          <select
            className="form-control"
            value={reason}
            onChange={this.handleReasonChange}
          >
            <option value="">Select Response</option>
            {predefinedOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>

        <button
          className="badge badge-primary mr-2 complete"
          onClick={this.updateCompleted}
          style={{ backgroundColor: "white", color: "green" }}
        >
          Mark as Completed
        </button>

        <div className="form-group">
          <label>
            <strong>Status:</strong>
          </label>
          {currentTicket.completed ? "Up Next" : "Pending"}
        </div>

        {currentTicket ? (
          <div className="edit-form">
            <form>
              <div className="form-group">
                <label htmlFor="ticketNumber">Ticket Number</label>
                <input
                  type="text"
                  className="form-control"
                  id="ticketnumber"
                  value={currentTicket.ticketnumber}
                  disabled
                  onChange={this.onChangeTicketNumber}
                />
              </div>
              <div className="form-group">
                <label htmlFor="service">Service</label>
                <input
                  type="text"
                  className="form-control"
                  id="service"
                  value={currentTicket.service}
                  onChange={this.onChangeService}
                />
              </div>
              <div className="form-group">
                <label htmlFor="name">Full Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  value={currentTicket.name}
                  onChange={this.onChangeName}
                />
              </div>
              <div className="form-group">
                <label htmlFor="phone">Phone</label>
                <input
                  type="text"
                  className="form-control"
                  id="phone"
                  value={currentTicket.phone}
                  onChange={this.onChangePhone}
                />
              </div>
              <div className="form-group">
                <label htmlFor="companyname">Company name</label>
                <input
                  type="text"
                  className="form-control"
                  id="companyname"
                  value={currentTicket.companyname}
                  disabled
                  onChange={this.onChangeCompanyName}
                />
              </div>
            </form>



            <p>{this.state.message}</p>
          </div>
        ) : (
          <div>
            <br />
            <p>Please click on a Ticket...</p>
          </div>
        )}
      </div>
    );
  }
}

