import React, { Component} from "react";
import TicketDataService from "../services/ticket.service";
import ServiceType from "../services/service.type";
import "./style.css"; // import the CSS file
import "./listStyle.css";
import "../utils/firebase";
import database from "../utils/firebase";
import Ticket from "./ticket.component";
import SpeakerIcon from '../components/speaker-icon'; // Import the SpeakerIcon js drawable component

export default class TicketsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tickets: [],
      selectedServices: [],
      branchDropdown: [],
      selectedBranch: "",
      verificationValue: "",
      showVerificationModal: false,
      allServices: [],
      currentTicket: null,
      currentIndex: -1,
      isComplete: false,
      isSaveButtonEnabled: false,
      speakerIconVisible: false // Add speaker icon visibility state
    };
    this.refreshList = this.refreshList.bind(this);
    this.setActiveTicket = this.setActiveTicket.bind(this);
    this.removeAllTickets = this.removeAllTickets.bind(this);
    this.onDataChange = this.onDataChange.bind(this);
    this.handleNextClick = this.handleNextClick.bind(this);
    this.handleMarkComplete = this.handleMarkComplete.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.fetchServices = this.fetchServices.bind(this);
    this.handleServiceCheck = this.handleServiceCheck.bind(this);
  }

  fetchServices() {
    ServiceType.getAll().on("value", (snapshot) => {
      const services = [];

      snapshot.forEach((item) => {
        const serviceData = {
          key: item.key,
          name: item.val().name
        };
        services.push(serviceData);
      });

      this.setState({ allServices: services });
    });
  }

  fetchBranches() {
    database.ref("Branches").on("value", (snapshot) => {
      let branchList = [];
      snapshot.forEach((snap) => {
        branchList.push({ name: snap.key, value: snap.val() });
      });

      this.setState({ branchDropdown: branchList });
    });
  }

  handleServiceCheck(e, serviceKey) {
    if (e.target.checked) {
      this.setState(
        { selectedServices: [...this.state.selectedServices, serviceKey] },
        () => {
          this.enableSaveButton();
        }
      );
    } else {
      this.setState(
        {
          selectedServices: this.state.selectedServices.filter(
            (item) => item !== serviceKey
          ),
        },
        () => {
          this.enableSaveButton();
        }
      );
    }
  }

  componentDidMount() {
    TicketDataService.getAll().on("value", this.onDataChange);
    this.fetchServices();
    this.fetchBranches();
  
    try {
      // Parse 'selectedServices' from localStorage
      const selectedServicesStorage = localStorage.getItem('selectedServices');
      const selectedServices = selectedServicesStorage ? JSON.parse(selectedServicesStorage) : [];
      this.setState({ selectedServices });
  
      // Parse 'branchName' from localStorage
      const branchNameStorage = localStorage.getItem('branchName');
      const branchNameFromStorage = branchNameStorage ? JSON.parse(branchNameStorage) : '';
      this.setState({ selectedBranch: branchNameFromStorage });
  
      // Parse 'deskName' from localStorage
      const deskNameStorage = localStorage.getItem('deskName');
      const deskNameFromStorage = deskNameStorage ? JSON.parse(deskNameStorage) : '';
      this.setState({ deskName: deskNameFromStorage });
    } catch (error) {
      console.error("Error parsing JSON from localStorage:", error);
      // Here, you can handle the error or set a default state
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedServices !== this.state.selectedServices) {
      const ref = TicketDataService.getAll();
      ref.off('value', this.onDataChange);
      ref.on('value', this.onDataChange);
    }
  }

  componentWillUnmount() {
    TicketDataService.getAll().off("value", this.onDataChange);
  }

  /**
  // method to handle branchName input change
  handleBranchNameChange = (event) => {
  this.setState({ branchName: event.target.value });
  // Save branch name to localStorage
  localStorage.setItem('branchName', this.state.branchName);
  };
  **/

  // method to handle branchName select change
  handleBranchSelect = (event) => {
    this.setState(
      { selectedBranch: event.target.value, showVerificationModal: true },
      () => {
        this.enableSaveButton();
      }
    );
  };

  handleVerificationSubmit = (event) => {
    event.preventDefault();
    if (this.state.verificationValue === this.state.branchDropdown.find(branch => branch.name === this.state.selectedBranch)?.value) {
      // Save branch name to localStorage
      localStorage.setItem("branchName", JSON.stringify(this.state.selectedBranch));
      this.setState({ showVerificationModal: false, verificationValue: "" });
    } else {
      alert("Incorrect verification value, please try again.");
      this.setState({ verificationValue: "" });
    }
  };

  // method to handle deskName input change
  handleDeskNameChange = (event) => {
    this.setState({ deskName: event.target.value }, () => {
      this.enableSaveButton();
    });
  };

  enableSaveButton = () => {
    this.setState({ isSaveButtonEnabled: true });
  };

  handleSaveButtonClick = () => {
    localStorage.clear();
    localStorage.setItem(
      "selectedServices",
      JSON.stringify(this.state.selectedServices)
    );
    localStorage.setItem("branchName", JSON.stringify(this.state.selectedBranch));
    localStorage.setItem("deskName", JSON.stringify(this.state.deskName));
    this.setState({ isSaveButtonEnabled: false });
    alert("Saved successfully!");
    window.location.reload();
    //this.forceUpdate();
  };

  onDataChange(items) {
    let tickets = [];
    items.forEach((item) => {
      let key = item.key;
      let data = item.val();
      //display only tickets not completed
      if (data.completed === false) {
        // Check for companyName as branchName
        // and service being either "Complaint", "Inquiry", or "Open Account"
        //const serviceList = ["Complaint", "Inquiry", "Open Account"];
        if (data.companyName === this.state.selectedBranch && this.state.selectedServices.includes(data.service) && (data.announced === "" || data.announced.includes("ticket") || data.announced.includes("Ticket"))) {
          tickets.push({
            key: key,
            ticketnumber: data.ticketNumber,
            service: data.service,
            phone: data.phone,
            name: data.name,
            companyname: data.companyName,
            completed: data.completed,
            announced: data.announced, // Include announced key in the tickets
          });
        }
        console.log(this.state.selectedServices); // log filtered tickets
      }
    });
    this.setState({
      tickets: tickets,
      currentTicket: tickets.length > 0 ? tickets[0] : null,
      currentIndex: 0,
      isComplete: false
    });
  }

  refreshList() {
    this.setState({
      currentTicket: null,
      currentIndex: -1,
      speakerIconVisible: false // Reset speaker icon visibility when refreshing
    });
  }

  setActiveTicket(ticket, index) {
    this.setState({
      currentTicket: ticket,
      currentIndex: index,
      isComplete: false
    });
  }

  removeAllTickets() {
    TicketDataService.deleteAll
      ()
      .then(() => {
        this.refreshList();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  handleNextClick() {
    if (this.state.currentIndex + 1 === this.state.tickets.length) {
      this.setState({
        currentIndex: -1,
        currentTicket: null,
        completed: false,
      });
    } else {
      this.setState((prevState) => ({
        currentIndex: prevState.currentIndex + 1,
        currentTicket: prevState.tickets[prevState.currentIndex + 1],
        completed: false,
      }));
    }
  }

  handleMarkComplete() {
    if (this.state.currentTicket.completed) {
      return;
    }
    const { key, ticketnumber, service, phone, name, companyname } = this.state.currentTicket;
    TicketDataService.update(key, {
      ticketNumber: ticketnumber,
      service: service,
      phone: phone,
      name: name,
      companyName: companyname,
      completed: true,
    })
      .then(() => {
        this.setState((prevState) => ({
          currentTicket: { ...prevState.currentTicket, completed: true },
          completed: true,
        }));
      })
      .catch((e) => {
        console.log(e);
      });
  }

  handleDelete() {
    const { key } = this.state.currentTicket;
    TicketDataService.delete(key)
      .then(() => {
        this.setState({
          currentIndex: -1,
          currentTicket: null,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  render() {
    const { tickets, currentTicket, currentIndex, completed, allServices, selectedBranch, deskName } = this.state;
    // Check for internet connectivity
    const isOffline = !navigator.onLine;

    // Calculate the total count of tickets
    const totalTicketsCount = tickets.length;

    return (
      <div className="container">
        {isOffline && <div className="alert alert-danger">No internet connection found.</div>}
        <div className="row">
          {/* Column for Selected Services and Services Interface */}
          <div className="col-md-4">
            <h4>Select Branch & Services</h4>
            <select
              className="form-control"
              id="branchName"
              value={selectedBranch}
              onChange={this.handleBranchSelect}
              required
            >
              <option>Select a branch</option>
              {this.state.branchDropdown.map((branch, index) => (
                <option key={index} value={branch.name}>
                  {branch.name}
                </option>
              ))}
            </select>

            {
              this.state.showVerificationModal && (
                <div>
                  <form onSubmit={this.handleVerificationSubmit}>
                    <label htmlFor="verificationValue">Enter verification value:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="verificationValue"
                      value={this.state.verificationValue}
                      onChange={(e) => this.setState({ verificationValue: e.target.value })}
                      required
                    />
                    <button type="submit" className="btn btn-primary mt-2">
                      Confirm
                    </button>
                  </form>
                </div>
              )
            }

            <div className="form-group">
              <label htmlFor="deskName">Provide your Desk name below:</label>
              <input
                type="text"
                className="form-control"
                id="deskName"
                value={deskName}
                onChange={this.handleDeskNameChange}
                required
              />
            </div>

            {allServices.map((service) => (
              <div key={service.key} className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={service.key}
                  id={`service-${service.key}`}
                  onChange={(e) => this.handleServiceCheck(e, service.key)}
                  checked={this.state.selectedServices.includes(service.key)} // This line was modified
                />
                <label className="form-check-label" htmlFor={`service-${service.key}`}>
                  {service.key}
                </label>
              </div>
            ))}


            <button
              className="btn btn-primary mt-2"
              disabled={!this.state.isSaveButtonEnabled}
              onClick={this.handleSaveButtonClick}
            >
              Save
            </button>
          </div>

          {/* Column for Tickets List */}
          <div className="col-md-4">

            {/*<h4>Tickets List</h4>*/}

            {/* Show total count of tickets at the top */}
            <h4>Total Tickets: {totalTicketsCount}</h4>

            <div className="ticket-list-container"> {/* Wrap the ticket list in the scrollable container */}
              <ul className="list-group">
                {tickets &&
                  tickets.map((ticket, index) => (
                    <li
                      className={
                        "list-group-item " +
                        (index === currentIndex ? "active" : "")
                      }
                      key={index}
                      // Add pointer-events property to disable interaction
                      //style={{ pointerEvents: "none" }}
                      // Add onClick event handler to make the ticket clickable
                      onClick={() => this.setActiveTicket(ticket, index)}
                    >
                      <span> {ticket.ticketnumber} &bull;</span>
                      {ticket.announced && ticket.announced.includes("Ticket") && <SpeakerIcon />}
                    </li>
                  ))}
              </ul>
            </div>
          </div>

          {/* Column for Ticket Components */}
          <div className="col-md-4">
            {currentTicket ? (
              <Ticket
                ticket={currentTicket}
                refreshList={this.refreshList}
              />
            ) : (
              <div>
                <br />
                <p>Ticket details will be shown here...</p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

}

export function getDeskName() {
  return JSON.parse(localStorage.getItem("deskName"));
}

export const branchNameFromStorage = JSON.parse(localStorage.getItem("branchName"));
